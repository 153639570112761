import { mapActions, mapGetters, mapMutations } from "vuex";
import { getProductPriceTitle, resolveProductImage, routeToProduct } from "@/helpers";
import { debounce, map } from "lodash";

export default {
    name: "basket-item",
    props: {
        basketHasPromotion: {
            type: Boolean,
            default: false,
        },
        product: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            selectedCount: 1,
        };
    },
    computed: {
        ...mapGetters({
            basket: "basket/basket",
            user: "profile/user",
            promotionResponse: "basket/promotionResponse"
        }),
        hasPromotion() {
            return this.product.select_type.hasActivePromotion || this.product.select_type.hasIndividualDiscount;
        },
        currentProductPrice() {
            return this.product.select_type.hasActivePromotion || this.product.select_type.hasIndividualDiscount
                ? this.product.select_type.oldPrice
                : this.product.select_type.price;
        },
    },
    async created() {
        await this.fetchPromotionData();
    },
    methods: {
        getProductPriceTitle,
        routeToProduct,
        resolveProductImage,
        ...mapMutations({
            increment: "basket/INCREMENT_BASKET_LIST_COUNT",
            decrement: "basket/DECREMENT_BASKET_LIST_COUNT",
            setCount: "basket/SET_BASKET_LIST_COUNT",
            remove: "basket/REMOVE_ITEM_FROM_BASKET",
        }),

        ...mapActions({
            fetchPromotion: "basket/FETCH_PROMOTION",
        }),

        fetchPromotionData: debounce(async  function () {
            let payload = {}
            payload.products = map(this.basket, product => ({
                id: product.id,
                product_price_id: product.select_type.id,
                count: product.select_count,
                is_present: product.isPresent || false,
                is_added_from_sale: product.isAddedFromSale || false,
            }));

            await this.fetchPromotion(payload)
        }, 500 ),

        findCurrentProductPromotionBanner(id) {
            if (!this.promotionResponse || !this.promotionResponse.closestSales) return null;

            const promotion = this.promotionResponse.closestSales.find(promo =>
                promo.productPricesToApply.includes(id)
            );

            return promotion || null;
        },

        async changeCount(type, count = 1) {
            if (type === "increment") {
                this.increment(this.product);
            } else if (type === "decrement") {
                this.decrement(this.product);
            } else {
                if (count) {
                    this.setCount({
                        product: this.product,
                        select_count: parseInt(count),
                    });
                }
            }
            await this.fetchPromotionData();
        },

        async handleRemove(item) {
            this.remove(item)
            await this.fetchPromotionData();
        }
    },
};
